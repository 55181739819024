<template>
  <HomePage />
</template>

<script>
import HomePage from './components/home.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
