<template>
  <div class="wrap">
    <div class="header-bg">
      <img class="img" :src="require('@/assets/images/header-bg.png')" />
    </div>
    <div class="header-line" />
    <div class="logo">
      <img class="img" :src="require('@/assets/images/logo.png')" />
    </div>
    <a href="#" class="home-page header-right-item" @click.prevent="goIndex"
      >首页</a
    >
    <a href="#" class="partner header-right-item" @click.prevent="goPartner"
      >合作伙伴</a
    >
    <a href="#" class="contact header-right-item" @click.prevent="goContact"
      >联系我们</a
    >
    <div class="title-img">
      <img class="img" :src="require('@/assets/images/title.png')" />
    </div>
    <div class="description-container">
      <div class="description-title">关于购省鸭</div>
      <div class="description-content">
        购省鸭是一个优惠券分发+赚取销售全网商品佣金奖励的二合一平台。它提供了淘宝京东拼多多等主流
        电商平台的内部优惠券，网购下单前，先在购省鸭查看相关商品，找到优惠券再购买，额外省一笔钱。
        介绍好友使用购省鸭，他就成了你的团队成员，团队成员通过购省鸭领券购物，推荐人可获得购物佣金。
        购物省钱，分享赚钱，轻松创业！
      </div>
    </div>
    <div class="phone-img">
      <img class="img" :src="require('@/assets/images/phone.png')" />
    </div>
    <div class="main-bg">
      <img class="img" :src="require('@/assets/images/main-bg.png')" />
    </div>
    <div class="partner-container">
      <div class="partner-title">购省鸭合作伙伴</div>
      <div class="partner-img">
        <img class="img" :src="require('@/assets/images/partner.png')" />
      </div>
    </div>
    <div class="contact-container">
      <div class="contact-title">联系我们</div>
      <div class="contact-phone">13279222236</div>
      <div class="contact-email">service@goshengya.com</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  props: {
    msg: String,
  },
  setup() {
    const partnerTop = document.body.clientWidth * 0.53;
    const contactTop = document.body.clientWidth * 1.06;

    const goIndex = () => {
      location.reload();
    };

    const goPartner = () => {
      window.scrollTo({ top: partnerTop, behavior: "smooth" });
    };

    const goContact = () => {
      window.scrollTo({ top: contactTop, behavior: "smooth" });
    };

    return {
      goIndex,
      goPartner,
      goContact,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; /*初始状态 透明度为0*/
  }
  50% {
    opacity: 0; /*中间状态 透明度为0*/
  }
  100% {
    opacity: 1; /*结尾状态 透明度为1*/
  }
}

.header-bg {
  width: 100vw;
  height: 52vw;
  .img {
    width: 100%;
    height: 100%;
  }
}

.header-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 5.56vw;
  background: #000000;
  opacity: 0.5;
}

.logo {
  width: 10.97vw;
  height: 3.13vw;
  position: absolute;
  top: 1.25vw;
  left: 9vw;
  -webkit-animation-name: fadeIn; /*动画名称*/
  -webkit-animation-duration: 3s; /*动画持续时间*/
  -webkit-animation-iteration-count: 1; /*动画次数*/
  -webkit-animation-delay: 0s; /*延迟时间*/
  .img {
    width: 100%;
    height: 100%;
  }
}

.header-right-item {
  height: 5.56vw;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 5.56vw;
}

.home-page {
  position: absolute;
  top: 0;
  right: 26.6vw;
}

.partner {
  position: absolute;
  top: 0;
  right: 17.5vw;
}

.contact {
  position: absolute;
  top: 0;
  right: 8vw;
}

.title-img {
  width: 39vw;
  height: 9vw;
  position: absolute;
  top: 14vw;
  left: 12.7vw;
  .img {
    width: 100%;
    height: 100%;
  }
}

.description-container {
  position: absolute;
  top: 26.25vw;
  left: 12.7vw;
  .description-title {
    height: 38px;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    line-height: 38px;
  }
  .description-content {
    width: 44.03vw;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    margin-top: 5px;
  }
}

.phone-img {
  width: 29.24vw;
  height: 32.36vw;
  position: absolute;
  top: 10.83vw;
  right: 16.25vw;
  .img {
    width: 100%;
    height: 100%;
  }
}

.main-bg {
  width: 100vw;
  height: 52vw;
  .img {
    width: 100%;
    height: 100%;
  }
}

.partner-container {
  position: absolute;
  top: 67.68vw;
  left: 18.06vw;
  .partner-title {
    width: 41.89vw;
    height: 38px;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    line-height: 38px;
    text-align: center;
  }
  .partner-img {
    width: 41.89vw;
    height: 6.94vw;
    margin-top: 3.26vw;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}

.contact-container {
  margin-top: 5.14vw;
  .contact-title {
    width: 100vw;
    height: 65px;
    font-size: 48px;
    font-weight: 500;
    color: #000000;
    line-height: 65px;
    text-align: center;
  }
  .contact-phone {
    width: 100vw;
    height: 38px;
    font-size: 28px;
    font-weight: 400;
    color: #000000;
    line-height: 38px;
    text-align: center;
    margin-top: 8px;
  }
  .contact-email {
    width: 100vw;
    height: 38px;
    font-size: 28px;
    font-weight: 400;
    color: #000000;
    line-height: 38px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 28.26vw;
  }
}
</style>
